import React from "react";
import {
  List,
  TextField,
  ArrayField,
  Datagrid,
  NumberField,
  SingleFieldList,
  ChipField,
  DateField,
} from "react-admin";

const AllFacilitiesShow = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="zip" />
      <TextField source="state" />
      <TextField source="organization.name" />
      <ArrayField source="userFacilities">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="ID" />
          <TextField source="user.firstName" label="First Name" />
          <TextField source="user.lastName" label="Last Name" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="controllers">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField>
      <DateField source="createdOn" />
      <DateField source="updatedOn" />
    </Datagrid>
  </List>
);

export default AllFacilitiesShow;
