import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useGetList, SelectInput, useDataProvider } from "react-admin";
import { useWatch } from "react-hook-form";

const Picker = ({ resource, source, label, optionText }) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [isLoadingCurrentItem, setIsLoadingCurrentItem] = useState(false);

  const { data: choices, isLoading: isLoadingChoices } = useGetList(resource);
  const itemId = useWatch({ name: source });

  const dataProvider = useDataProvider();

  useEffect(() => {
    if (itemId !== null && itemId !== undefined) {
      setIsLoadingCurrentItem(true);
      dataProvider
        .getOne(resource, { id: itemId })
        .then(({ data }) => {
          setCurrentItem(data);
        })
        .catch(() => {
          setCurrentItem(null);
        })
        .finally(() => {
          setIsLoadingCurrentItem(false);
        });
    } else {
      setCurrentItem(null);
    }
  }, [dataProvider, itemId, resource]);

  const isLoading = isLoadingChoices || isLoadingCurrentItem;

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (!choices) {
    return <span>Data not available</span>;
  }

  const choicesWithCurrentItem = currentItem
    ? choices.find((choice) => choice.id === itemId)
      ? choices
      : [...choices, currentItem]
    : choices;

  return (
    <SelectInput
      label={label}
      source={source}
      choices={choicesWithCurrentItem}
      optionText={optionText}
    />
  );
};

Picker.propTypes = {
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optionText: PropTypes.string.isRequired,
};

export default Picker;
