import { List, TextField, Datagrid, DateField } from "react-admin";

const AllUserFacilitiesList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="user.id" label="User ID" />
      <TextField source="user.firstName" label="First Name" />
      <TextField source="user.lastName" label="Last Name" />/
      <TextField source="facility.id" label="Facility ID" />
      <TextField source="facility.name" label="Facility Name" />
      <DateField source="createdOn" />
      <DateField source="updatedOn" />
    </Datagrid>
  </List>
);

export default AllUserFacilitiesList;
