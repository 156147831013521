import { Edit, SimpleForm, TextInput, NumberInput,DateInput } from "react-admin";

const AllFrimwaresEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="version" />
      <TextInput source="url" />
      <NumberInput source="deviceType.id"  />
      <DateInput source="createdOn" disabled/>
      <DateInput source="updatedOn" disabled/>
    </SimpleForm>
  </Edit>
);

export default AllFrimwaresEdit;
