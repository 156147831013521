import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SDK from "casdoor-js-sdk";
import * as colors from "../../layout/colors";
import env from '../../config.js'

const getCasdoorConfig = () => ({
  serverUrl: env.VITE_CASDOOR_SERVER_URL,
  clientId: env.VITE_CASDOOR_SAFEEVAC_CLIENT_ID,
  organizationName: env.VITE_CASDOOR_SAFEEVAC_NAME,
  appName: env.VITE_CASDOOR_SAFEEVAC_APP_NAME,
  redirectPath: "/login",
});

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const casdoorConfig = getCasdoorConfig();
    const sdk = new SDK(casdoorConfig);
    window.sdk = sdk;

    sdk
      .exchangeForAccessToken()
      .then((resp) => {
        const accessToken = resp.access_token;
        const refreshToken = resp.refresh_token;
        const expiresIn = resp.expires_in;

        if (accessToken && refreshToken && expiresIn) {
          const accessTokenExpiresAt = new Date();
          accessTokenExpiresAt.setSeconds(
            accessTokenExpiresAt.getSeconds() + expiresIn - 60
          );

          localStorage.setItem(
            env.VITE_AUTH_ACCESS_TOKEN_NAME,
            accessToken
          );
          localStorage.setItem(
            env.VITE_AUTH_REFRESH_TOKEN_NAME,
            refreshToken
          );
          localStorage.setItem(
            env.VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME,
            accessTokenExpiresAt
          );
          navigate("/");
        } else {
          console.error("Failed to exchange token: Missing token parameters");
        }
      })
      .catch((err) => {
        console.error("Failed to exchange token:", err);
      });
  }, [navigate]);

  const login = () => {
    window.sdk.signin_redirect();
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: colors.menuDarkColor,
    color: "lightGrey",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
  };

  const buttonStyle = {
    backgroundColor: colors.primaryColor,
    color: "black",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    marginBottom: "15px",
    cursor: "pointer",
    fontSize: "18px",
  };

  return (
    <div style={containerStyle}>
      <h1>SafeEvac Admin</h1>
      <button style={buttonStyle} onClick={login}>
        Login
      </button>
    </div>
  );
};

export default Login;
