export const UserIcon = () => <i className="bi bi-people-fill"></i>;
export const OrganizationIcon = () => <i className="bi bi-buildings-fill"></i>;
export const FacilityIcon = () => <i className="bi bi-building-fill"></i>;
export const FirmwareIcon = () => <i className="bi bi-hdd-fill"></i>;
export const CompatibilityIcon = () => <i className="bi bi-ui-checks"></i>;
export const ControllerVersionIcon = () => (
  <i className="bi bi-database-fill-check"></i>
);
export const UserRolesIcon = () => (
  <i className="bi bi-person-fill-exclamation"></i>
);
export const UserFacilitiesIcon = () => (
  <i className="bi bi-person-lines-fill"></i>
);
