import {
  Admin,
  Resource,
  ListGuesser,
  EditGuesser,
  ShowGuesser,
} from "react-admin";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import Login from "./components/login/Login";
import PrivateRoute from "./components/login/PrivateRoute";
import { dataProvider } from "./dataProvider";
import { lightTheme, darkTheme } from "./layout/themes";
import "./layout/iconStyles.css";
import * as User from "./components/user";
import * as Organization from "./components/organizations";
import * as Facility from "./components/facilities";
import * as Compatibility from "./components/compatibilities";
import * as ControllerVersion from "./components/controller-version";
import * as UserFacilities from "./components/user-facilities";
import * as UserRoles from "./components/user-roles";
import * as Firmware from "./components/firmware";
import {
  UserIcon,
  OrganizationIcon,
  FacilityIcon,
  FirmwareIcon,
  CompatibilityIcon,
  ControllerVersionIcon,
  UserRolesIcon,
  UserFacilitiesIcon,
} from "./layout/icons";

export const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<PrivateRoute />}>
        <Route
          path="*"
          element={
            <Admin
              dataProvider={dataProvider}
              layout={Layout}
              darkTheme={darkTheme}
              lightTheme={lightTheme}
            >
              <Resource
                name="allOrganizations"
                list={ListGuesser}
                create={Organization.AllOrganizationsCreate}
                edit={Organization.AllOrganizationsEdit}
                show={ShowGuesser}
                icon={OrganizationIcon}
                options={{ label: "Organizations" }}
              />
              <Resource
                name="allUsers"
                list={User.AllUserList}
                create={User.AllUserCreate}
                edit={User.AllUserEdit}
                show={User.AllUsersShow}
                icon={UserIcon}
                options={{ label: "Users" }}
              />
              <Resource
                name="allUserFacilities"
                list={UserFacilities.AllUserFacilitiesList}
                create={UserFacilities.AllUserFacilitiesCreate}
                edit={UserFacilities.AllUserFacilitiesEdit}
                show={ShowGuesser}
                icon={UserFacilitiesIcon}
                options={{ label: "User Facilities" }}
              />
              <Resource
                name="allUserRoles"
                list={UserRoles.AllUserRolesList}
                create={UserRoles.AllUserRolesCreate}
                edit={UserRoles.AllUserRolesEdit}
                show={ShowGuesser}
                icon={UserRolesIcon}
                options={{ label: "User Roles" }}
              />
              <Resource
                name="allFacilities"
                list={Facility.AllFacilitiesList}
                create={Facility.AllFacilitiesCreate}
                edit={Facility.AllFacilitiesEdit}
                show={Facility.AllFacilitiesShow}
                icon={FacilityIcon}
                options={{ label: "Facilities" }}
              />
              <Resource
                name="allControllerVersions"
                list={ListGuesser}
                create={ControllerVersion.AllControllerVersionsCreate}
                edit={EditGuesser}
                show={ShowGuesser}
                icon={ControllerVersionIcon}
                options={{ label: "ControllerVersions" }}
              />
              <Resource
                name="allFirmwares"
                list={Firmware.AllFrimwaresList}
                create={Firmware.AllFrimwaresCreate}
                edit={Firmware.AllFrimwaresEdit}
                show={ShowGuesser}
                icon={FirmwareIcon}
                options={{ label: "Firmwares" }}
              />
              <Resource
                name="allCompatibilities"
                list={ListGuesser}
                create={Compatibility.AllCompatibilitiesCreate}
                edit={EditGuesser}
                show={ShowGuesser}
                icon={CompatibilityIcon}
                options={{ label: "Compatibilities" }}
              />
            </Admin>
          }
        />
      </Route>
    </Routes>
  </Router>
);
