const resourceConfigs = {
  allOrganizations: {
    fields: "id name createdOn updatedOn",
    oneQuery: "organization",
    deleteMutation: "deleteOrganization",
    updateMutation: "updateOrganization",
    createMutation: "createOrganization",
    createFields: ["name"],
    updateFields: ["id", "name"],
  },
  allFacilities: {
    fields:
      "id name address createdOn updatedOn",
    oneQuery: "facility",
    deleteMutation: "deleteFacility",
    updateMutation: "updateFacility",
    createMutation: "createFacility",
    createFields: [
      "name",
      "address1",
      "address2",
      "city",
      "state",
      "zip",
      "organizationId",
    ],
    updateFields: [
      "id",
      "name",
      "address1",
      "address2",
      "city",
      "state",
      "zip",
      "organizationId",
    ],
  },
  allUsers: {
    fields:
      "id firstName lastName ssoId email organization{id name} facilities{id facility{id name}} userRoles{id role{id name}} createdOn updatedOn",
    oneQuery: "user",
    deleteMutation: "deleteUser",
    updateMutation: "updateUser",
    createMutation: "createUser",
    createFields: ["email", "firstName", "lastName", "ssoId", "organizationId"],
    updateFields: [
      "id",
      "email",
      "firstName",
      "lastName",
      "ssoId",
      "organizationId",
    ],
  },
  allUserFacilities: {
    fields:
      "id user{id firstName lastName} facility{id name} createdOn updatedOn",
    oneQuery: "userFacility",
    deleteMutation: "deleteUserFacility",
    updateMutation: "updateUserFacility",
    createMutation: "createUserFacility",
    createFields: ["userId", "facilityId"],
    updateFields: ["id", "userId", "facilityId"],
  },
  allUserRoles: {
    fields: "id user{id firstName lastName} role{id name} createdOn updatedOn",
    oneQuery: "userRole",
    deleteMutation: "deleteUserRole",
    updateMutation: "updateUserRole",
    createMutation: "createUserRole",
    createFields: ["userId", "roleId"],
    updateFields: ["id", "userId", "roleId"],
  },
  allControllerVersions: {
    fields: "id version url createdOn updatedOn",
    oneQuery: "controllerVersion",
    deleteMutation: "deleteControllerVersion",
    updateMutation: "updateControllerVersion",
    createMutation: "createControllerVersion",
    createFields: ["version", "url"],
    updateFields: ["id", "version", "url"],
  },
  allFirmwares: {
    fields: "id name version url deviceType{id} createdOn updatedOn",
    oneQuery: "firmware",
    deleteMutation: "deleteFirmware",
    updateMutation: "updateFirmware",
    createMutation: "createFirmware",
    createFields: ["name", "version", "url", "deviceTypeId"],
    updateFields: ["id", "name", "version", "url", "deviceTypeId"],
  },
  allCompatibilities: {
    fields: "id controllerVersion{id} firmware{id} createdOn updatedOn",
    oneQuery: "compatibility",
    deleteMutation: "deleteCompatibility",
    updateMutation: "updateCompatibility",
    createMutation: "createCompatibility",
    createFields: ["controllerVersionId", "firmwareId"],
    updateFields: ["id", "controllerVersionId", "firmwareId"],
  },
  allRoles: {
    fields: "id name createdOn updatedOn",
    oneQuery: "role",
    deleteMutation: "deleteRole",
    updateMutation: "updateRole",
    createMutation: "createRole",
    createFields: ["name"],
    updateFields: ["id", "name"],
  },
};

export default resourceConfigs;
