import { defaultDarkTheme, defaultLightTheme } from "react-admin";
import {
  primaryColor,
  lightPrimaryColor,
  menuLightColor,
  menuDarkColor,
} from "./colors";

const commonTheme = (isDarkTheme) => {
  const primary = isDarkTheme ? primaryColor : lightPrimaryColor;
  const menu = isDarkTheme ? menuDarkColor : menuLightColor;
  return {
    palette: {
      mode: isDarkTheme ? "dark" : "light",
      primary: { main: primary },
      secondary: { main: menu },
    },
    components: {
      RaAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: menu,
          },
        },
      },
      RaLayout: {
        styleOverrides: {
          root: {
            backgroundColor: menu,
            overflow: "hidden",
          },
        },
      },
      RaSidebar: {
        styleOverrides: {
          root: {
            backgroundColor: menu,
          },
        },
      },
      RaLabeled: {
        styleOverrides: {
          root: {
            fontSize: "1.5rem",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            paddingBottom: "10px",
          },
        },
      },
    },
  };
};

const darkSpecifics = {
  palette: {
    background: { default: menuDarkColor },
  },
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: `5px solid ${menuDarkColor}`,
          "&.RaMenuItemLink-active": {
            borderLeft: `5px solid ${primaryColor}`,
            "& .RaMenuItemLink-icon": {
              color: primaryColor,
            },
          },
        },
      },
    },
  },
};

const lightSpecifics = {
  palette: {},
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: primaryColor,
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          "&.RaMenuItemLink-active": {
            "& .RaMenuItemLink-icon": {
              color: lightPrimaryColor,
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            backgroundColor: menuLightColor,
          },
        },
      },
    },
  },
};

const createTheme = (isDarkTheme) => {
  const defaultTheme = isDarkTheme ? defaultDarkTheme : defaultLightTheme;
  const specifics = isDarkTheme ? darkSpecifics : lightSpecifics;
  const common = commonTheme(isDarkTheme);
  return {
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      ...common.palette,
      ...specifics.palette,
    },
    components: {
      ...defaultTheme.components,
      ...common.components,
      ...specifics.components,
    },
  };
};

const darkTheme = createTheme(true);
const lightTheme = createTheme(false);

export { darkTheme, lightTheme };
