import React from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  ArrayField,
  Datagrid,
  DateField,
} from "react-admin";

const AllFacilitiesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="zip" />
      <TextField source="state" />
      <TextField source="organization.id" label="Organization ID"/>
      <TextField source="organization.name" label="Organization Name" />
      <ArrayField source="userFacilities">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="User-Facility ID" />
          <TextField source="user.firstName" label="First Name" />
          <TextField source="user.lastName" label="Last Name" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="controllers">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="Controller ID" />
          <TextField source="name" label="Controller Name" />
        </Datagrid>
      </ArrayField>
      <DateField source="createdOn" />
      <DateField source="updatedOn" />
    </SimpleShowLayout>
  </Show>
);

export default AllFacilitiesShow;
