import { List, TextField, Datagrid, UrlField, DateField } from "react-admin";

const AllFrimwaresList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="version" />
      <UrlField source="url" />
      <TextField source="deviceType.id" />
      <DateField source="createdOn" />
      <DateField source="updatedOn" />
    </Datagrid>
  </List>
);

export default AllFrimwaresList;
