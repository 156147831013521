import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import env from './config.js'

const httpLink = new HttpLink({ uri: env.VITE_APOLLO_URL });

// This link will set the Authorization header for each GraphQL request.
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: `Bearer ${
        localStorage.getItem(env.VITE_AUTH_ACCESS_TOKEN_NAME) 
      }`,
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  // Combine the authentication link and the HTTP link.
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

export default client;
