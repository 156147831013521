import { Create, SimpleForm, TextInput, NumberInput,DateInput } from "react-admin";

const AllControllerVersionsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="version" />
      <TextInput source="url" />
      <DateInput source="createdOn" disabled/>
      <DateInput source="updatedOn" disabled/>
    </SimpleForm>
  </Create>
);

export default AllControllerVersionsCreate;
