import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
} from "react-admin";

const AllOrganizationsEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <DateInput source="createdOn" disabled />
      <DateInput source="updatedOn" disabled />
    </SimpleForm>
  </Edit>
);

export default AllOrganizationsEdit;
