import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayField,
  Datagrid,
  TextField,
} from "react-admin";
import Picker from "../Picker";

const AlluserEdit = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="ssoId" />
      <TextInput source="email" type="email" />
      <Picker
        resource="allOrganizations"
        source="organization.id"
        label="Organization"
        optionText="name"
        reference="allOrganizations"
      />
      <ArrayField source="facilities">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="Facility ID" />
          <TextField source="facility.name" label="Facility Name" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Create>
);

export default AlluserEdit;
