import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import env from '../../config.js'

const PrivateRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loggedIn = (
      localStorage.getItem(env.VITE_AUTH_ACCESS_TOKEN_NAME) &&
      localStorage.getItem(env.VITE_AUTH_REFRESH_TOKEN_NAME) &&
      localStorage.getItem(env.VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME)
    );

    if (!loggedIn) {
      navigate('/login');
    }
  }, [navigate]);

  return <Outlet />;
};

export default PrivateRoute;
