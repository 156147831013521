import { Edit, SimpleForm, DateInput, TextInput, NumberInput } from "react-admin";
import Picker from "../Picker";

const AllUserRolesEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <Picker
        resource="allUsers"
        source="user.id"
        label="User"
        optionText="email"
        reference="allUsers"
      />
      <Picker
        resource="allRoles"
        source="role.id"
        label="Role"
        optionText="name"
        reference="allRoles"
      />
      <DateInput source="createdOn" disabled />
      <DateInput source="updatedOn" disabled />
    </SimpleForm>
  </Edit>
);

export default AllUserRolesEdit;
