import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
} from "react-admin";
import Picker from "../Picker";

const AllFacilitiesCreate = (props) => (
  <Create>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" />
      <TextInput source="zip" />
      <TextInput source="state" />
      <DateInput source="createdOn" disabled />
      <DateInput source="updatedOn" disabled />
      <ArrayInput source="userFacilities">
        <SimpleFormIterator>
          <TextInput source="id" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="controllers">
        <SimpleFormIterator>
          <TextInput source="id" />
        </SimpleFormIterator>
      </ArrayInput>
      <Picker
        resource="allOrganizations"
        source="organization.id"
        label="Organization"
        optionText="name"
        reference="allOrganizations"
      />
    </SimpleForm>
  </Create>
);

export default AllFacilitiesCreate;
