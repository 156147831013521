import { List, TextField, Datagrid, DateField } from "react-admin";

const AllRolesList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="user.id" label="User ID" />
      <TextField source="user.firstName" label="First Name" />
      <TextField source="user.lastName" label="Last Name" />/
      <TextField source="role.id" label="Role ID" />
      <TextField source="role.name" label="Role" />
      <DateField source="createdOn" />
      <DateField source="updatedOn" />
    </Datagrid>
  </List>
);

export default AllRolesList;
