import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
} from "react-admin";

const AllOrganizationsCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <DateInput source="createdOn" disabled />
      <DateInput source="updatedOn" disabled />
    </SimpleForm>
  </Create>
);

export default AllOrganizationsCreate;
