const env = {
    "VITE_APOLLO_URL": window["env"]["VITE_APOLLO_URL"] || "http://localhost:4000/graphql",
    "VITE_CASDOOR_SERVER_URL": window["env"]["VITE_CASDOOR_SERVER_URL"] || "http://localhost:8000",
    "VITE_AUTH_ACCESS_TOKEN_NAME": window["env"]["VITE_AUTH_ACCESS_TOKEN_NAME"] || "access_token",
    "VITE_AUTH_REFRESH_TOKEN_NAME": window["env"]["VITE_AUTH_REFRESH_TOKEN_NAME"] || "refresh_token",
    "VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME": window["env"]["VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME"] || "access_token_expires_at",
    "VITE_CASDOOR_SAFEEVAC_CLIENT_ID": window["env"]["VITE_CASDOOR_SAFEEVAC_CLIENT_ID"] || "b7853758e405dca9c0a4",
    "VITE_CASDOOR_SAFEEVAC_NAME": window["env"]["VITE_CASDOOR_SAFEEVAC_NAME"] || "SafeEVAC",
    "VITE_CASDOOR_SAFEEVAC_APP_NAME": window["env"]["VITE_CASDOOR_SAFEEVAC_APP_NAME"] || "safeevac"
}

export default env;

