import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayField,
  Datagrid,
  TextField,
  DateInput
} from "react-admin";
import Picker from "../Picker";

const AllFacilitiesEdit = (props) => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" />
      <TextInput source="zip" />
      <TextInput source="state" />
      <Picker
        resource="allOrganizations"
        source="organization.id"
        label="Organization"
        optionText="name"
        reference="allOrganizations"
      />
      <DateInput source="createdOn" disabled />
      <DateInput source="updatedOn" disabled />
      <div style={{ marginBottom: "16px" }}>User-facilities</div>
      <ArrayField source="userFacilities">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="User-Facility ID" />
          <TextField source="user.firstName" label="First Name" />
          <TextField source="user.lastName" label="Last Name" />
        </Datagrid>
      </ArrayField>
      <div style={{ margin: "26px 0" }}>Controllers</div>
      <ArrayField source="controllers">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="Controller ID" />
          <TextField source="name" label="Controller Name" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
);

export default AllFacilitiesEdit;
