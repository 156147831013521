import React from "react";
import {
  Layout,
  AppBar,
  TitlePortal,
  UserMenu,
  ToggleThemeButton,
} from "react-admin";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import env from '../config.js'

const MyAppBar = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  const token = localStorage.getItem(
    env.VITE_AUTH_ACCESS_TOKEN_NAME
  );
  const decoded = jwtDecode(token);

  return (
    <AppBar
      userMenu={
        <UserMenu>
          <div style={{ padding: "16px", fontSize: "16px" }}>
            {decoded.name}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="primary" onClick={handleSignOut}>
              Sign Out
            </Button>
          </div>
        </UserMenu>
      }
      toolbar={<ToggleThemeButton />}
    >
      <TitlePortal />
    </AppBar>
  );
};

const CustomLayout = (props) => {
  return <Layout {...props} appBarAlwaysOn={true} appBar={MyAppBar}></Layout>;
};

export default CustomLayout;
