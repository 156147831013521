import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ArrayField,
  Datagrid,
  TextField,
} from "react-admin";
import Picker from "../Picker";

const AllUserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="ssoId" />
      <TextInput disabled source="email" type="email" />
      <Picker
        resource="allOrganizations"
        source="organization.id"
        label="Organization"
        optionText="name"
        reference="allOrganizations"
      />
      <DateInput source="createdOn" disabled/>
      <DateInput source="updatedOn" disabled/>
      <div style={{ marginBottom: "16px" }}>Facilities</div>
      <ArrayField source="facilities">
        <Datagrid bulkActionButtons={false}>
          <TextField source="id" label="Facility ID" />
          <TextField source="facility.name" label="Facility Name" />
        </Datagrid>
      </ArrayField>
      <div style={{ margin: "16px 0" }}>User-Roles</div>
      <ArrayField source="userRoles" link="show">
        <Datagrid bulkActionButtons={false}>
          <TextField source="role.id" label="ID" />
          <TextField source="role.name" label="Role" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
);

export default AllUserEdit;
