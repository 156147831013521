import React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  ArrayField,
  DateField,
} from "react-admin";
// import UserFilter from "../UserFilter";

// const renderFacilitiesAsList = (record) => {
//   return (
//     <ul>
//       {record.facilities?.map((facility, index) => (
//         <li key={index}>{facility.facility.name}</li>
//       ))}
//     </ul>
//   );
// };

const AlluserList = () => (
  <List
  // filters={<UserFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="ssoId" />
      <EmailField source="email" />
      <TextField source="organization.name" />
      {/* <FunctionField label="Facilities" render={renderFacilitiesAsList} /> */}
      <ArrayField source="facilities" link="show">
        <Datagrid bulkActionButtons={false}>
          <TextField source="facility.id" label="ID" />
          <TextField source="facility.name" label="Facility" />
        </Datagrid>
      </ArrayField>
      <ArrayField source="userRoles" link="show">
        <Datagrid bulkActionButtons={false}>
          <TextField source="role.id" label="ID" />
          <TextField source="role.name" label="Role" />
        </Datagrid>
      </ArrayField>
      <DateField source="createdOn" />
      <DateField source="updatedOn" />
    </Datagrid>
  </List>
);

export default AlluserList;
