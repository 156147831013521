import { Create, SimpleForm, TextInput, NumberInput,DateInput } from "react-admin";

const AllCompatibilitiesCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="controllerVersion.id" />
      <TextInput source="firmware.id" />
      <DateInput source="createdOn" disabled/>
      <DateInput source="updatedOn" disabled/>
    </SimpleForm>
  </Create>
);

export default AllCompatibilitiesCreate;
