import { Create, SimpleForm, DateInput, TextInput, NumberInput } from "react-admin";
import Picker from "../Picker";

const AllUserFacilitiesCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" disabled />
      <Picker
        resource="allUsers"
        source="user.id"
        label="User"
        optionText="email"
        reference="allUsers"
      />
      <Picker
        resource="allFacilities"
        source="facility.id"
        label="Facility"
        optionText="name"
        reference="allFacilities"
      />
      <DateInput source="createdOn" disabled />
      <DateInput source="updatedOn" disabled />
    </SimpleForm>
  </Create>
);

export default AllUserFacilitiesCreate;
