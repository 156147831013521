import { gql } from "@apollo/client";
import client from "./apolloClient";
import capitalizeFirstLetter from "./utility";
import resourceConfigs from "./resourceConfig";

const extractNestedIds = (data) => {
  const updatedData = { ...data };
  Object.keys(data).forEach((key) => {
    if (
      data[key] &&
      typeof data[key] === "object" &&
      Object.prototype.hasOwnProperty.call(data[key], "id")
    ) {
      updatedData[`${key}Id`] = data[key].id;
      delete updatedData[key];
    }
  });
  return updatedData;
};

const filterParameters = (input, allowedFields) => {
  return Object.keys(input)
    .filter((key) => allowedFields.includes(key))
    .reduce((obj, key) => {
      obj[key] = input[key];
      return obj;
    }, {});
};

export const dataProvider = {
  getList: async (resource) => {
    const operationName = capitalizeFirstLetter(resource);
    return client
      .query({
        query: gql`
            query ${operationName}  {
                ${resource} {
                    ${resourceConfigs[resource].fields}
                }
            }`,
        variables: {},
      })
      .then((result) => ({
        data: result.data[resource],
        total: result.data[`${resource}`].count,
      }));
  },
  getOne: async (resource, params) => {
    const operationName = capitalizeFirstLetter(
      resourceConfigs[resource].oneQuery
    );
    return client
      .query({
        query: gql`
            query ${operationName} ($id: String!) {
              ${resourceConfigs[resource].oneQuery}(id: $id) {
                    ${resourceConfigs[resource].fields}
                }
            }`,
        variables: {
          id: params.id,
        },
      })
      .then((result) => ({
        data: result.data[resourceConfigs[resource].oneQuery],
      }));
  },
  create: async (resource, params) => {
    const completeParameters = extractNestedIds(params.data);
    const parameters = filterParameters(
      completeParameters,
      resourceConfigs[resource].createFields
    );

    const mutationName = resourceConfigs[resource].createMutation;
    const inputName = resourceConfigs[resource].oneQuery;

    const operationName = capitalizeFirstLetter(mutationName);

    return client
      .mutate({
        mutation: gql`
            mutation ${operationName}($data: ${capitalizeFirstLetter(
          inputName
        )}CreateInput!) {
      ${mutationName}(data: $data) {
        ${resourceConfigs[resource].fields}
        }
      }`,
        variables: { data: parameters },
      })
      .then((result) => ({
        data: result.data[resourceConfigs[resource].createMutation],
      }));
  },
  update: async (resource, params) => {
    const completeParameters = extractNestedIds(params.data);
    const parameters = filterParameters(
      completeParameters,
      resourceConfigs[resource].updateFields
    );

    const mutationName = resourceConfigs[resource].updateMutation;
    const inputName = resourceConfigs[resource].oneQuery;

    const operationName = capitalizeFirstLetter(mutationName);

    return client
      .mutate({
        mutation: gql`
          mutation ${operationName}($data: ${capitalizeFirstLetter(
          inputName
        )}UpdateInput!) {
    ${mutationName}(data: $data) {
      ${resourceConfigs[resource].fields}
      }
    }`,
        variables: { data: parameters },
      })
      .then((result) => ({
        data: result.data[resourceConfigs[resource].updateMutation],
      }));
  },
  delete: async (resource, params) => {
    const operationName = capitalizeFirstLetter(
      resourceConfigs[resource].deleteMutation
    );
    return client
      .mutate({
        mutation: gql`
      mutation ${operationName} ($id: String!) {
        ${resourceConfigs[resource].deleteMutation}(id: $id) {
          ${resourceConfigs[resource].fields}
        }
      }`,
        variables: {
          id: params.id,
        },
      })
      .then((result) => ({
        data: params.ids,
      }));
  },
  // Missing full implementation, just deletes the first element of the array
  deleteMany: async (resource, params) => {
    const operationName = capitalizeFirstLetter(
      resourceConfigs[resource].deleteMutation
    );
    return client
      .mutate({
        mutation: gql`
      mutation ${operationName} ($id: String!) {
        ${resourceConfigs[resource].deleteMutation}(id: $id) {
          ${resourceConfigs[resource].fields}
        }
      }`,
        variables: {
          id: params.ids[0],
        },
      })
      .then((result) => ({
        data: params.ids,
      }));
  },
  // Missing implementation
  updateMany: async (resource, params) => {
    return client.query({}).then((result) => ({ data: result.data }));
  },
  // Missing implementation
  getMany: async (resource, params) => {
    return client.query({}).then((result) => ({ data: result.data }));
  },
  // Missing implementation
  getManyReference: async (resource) => {
    return client.query({}).then((result) => ({ data: result.data }));
  },
};
