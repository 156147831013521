import { Edit, SimpleForm, DateInput, TextInput, NumberInput } from "react-admin";
import Picker from "../Picker";

const AllUserFacilitiesEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" disabled />
      {/* <NumberInput source="user.id" /> */}
      {/* <NumberInput source="facility.id" /> */}
      <Picker
        resource="allUsers"
        source="user.id"
        label="User"
        optionText="email"
        reference="allUsers"
      />
      <Picker
        resource="allFacilities"
        source="facility.id"
        label="Facility"
        optionText="name"
        reference="allFacilities"
      />
      <DateInput source="createdOn"/>
      <DateInput source="updatedOn" disabled />
    </SimpleForm>
  </Edit>
);

export default AllUserFacilitiesEdit;
