import { Create, SimpleForm, TextInput, NumberInput,DateInput } from "react-admin";

const AllFrimwaresCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="version" />
      <TextInput source="url" />
      <TextInput source="deviceType.id"  />
      <DateInput source="createdOn" disabled/>
      <DateInput source="updatedOn" disabled/>
    </SimpleForm>
  </Create>
);

export default AllFrimwaresCreate;
